import { useQuery } from '@tanstack/react-query'
import { type ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import getCompany from '~/feature/companies/api/getCompany'
import companiesQueryKeys from '~/feature/companies/queryKeys/companiesQueryKeys'
import { get } from '../lib/fetch_api'
import type { UserRoles } from '../lib/role'
import type { Company } from '../models/company'

type SharedDataContextType = {
  isSharedDataLoading: boolean
  userRoles: UserRoles
  company: Company | null
  isAdmin: boolean
  isSuperAdmin: boolean
  isCompanyPerson: boolean
  fetchData: () => void
}

const SharedDataContext = createContext<SharedDataContextType | undefined>(undefined)

export default SharedDataContext

interface SharedDataProviderProps {
  children: ReactNode
}

export const SharedDataProvider = ({ children }: SharedDataProviderProps) => {
  const [isSharedDataLoading, setIsSharedDataLoading] = useState<boolean>(true)
  const [userRoles, setUserRoles] = useState<UserRoles>([])
  const [company, setCompany] = useState<Company | null>(null)
  const location = useLocation()
  const { company: company_code } = useParams<{ company: string }>()
  const pageAnswer = location.pathname.startsWith('/q/')
  const isAdmin = userRoles.includes('admin')
  const isSuperAdmin = userRoles.includes('super_admin')
  const isCompanyPerson = userRoles.includes('company_person')
  const pageCompanyCode = location.pathname.startsWith(`/companies/${company_code}`)

  useQuery({
    queryKey: companiesQueryKeys.detail(company_code ?? ''),
    queryFn: async () => {
      if (company_code === undefined) {
        setCompany(null)
        return null
      }
      const data = await getCompany(company_code)
      if (pageCompanyCode) {
        setCompany(data.company)
      } else {
        setCompany(null)
      }
      return data
    },
    enabled: isAdmin,
  })

  const fetchAuthData = useCallback(() => {
    setIsSharedDataLoading(true)
    get<{
      user: { roles: UserRoles }
      company: Company
    }>('/me')
      .then(data => {
        setUserRoles(data.user.roles as UserRoles)
        setCompany(data.company)
      })
      .catch(() => {
        setUserRoles([])
        setCompany(null)
      })
      .finally(() => {
        setIsSharedDataLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!pageAnswer) {
      fetchAuthData()
    } else {
      setIsSharedDataLoading(false)
    }
  }, [fetchAuthData, pageAnswer])

  return (
    <SharedDataContext.Provider
      value={{
        isSharedDataLoading,
        userRoles,
        company,
        isAdmin,
        isSuperAdmin,
        isCompanyPerson,
        fetchData: fetchAuthData,
      }}
    >
      {children}
    </SharedDataContext.Provider>
  )
}

export const useSharedData = (): SharedDataContextType => {
  const context = useContext(SharedDataContext)
  if (context === undefined) {
    throw new Error('useSharedData must be used within an SharedDataProvider')
  }
  return context
}
